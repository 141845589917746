import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  // process.env.REACT_APP_SUPABASE_URL,
  // process.env.REACT_APP_SUPABASE_PUBLIC_KEY
  'https://kvbcblnmjowrsbbvfrxo.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt2YmNibG5tam93cnNiYnZmcnhvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDUwMjI3NzgsImV4cCI6MTk2MDU5ODc3OH0.JbCkxFYEWWoUXPOOsV5IorAteoajQHkgpRWmW6dYBls'
);

export default supabase;
