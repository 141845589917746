import React, { useState } from 'react';
import { 
  Card, CardActions, CardMedia, CardContent, Container, Grid, InputBase, Button, TextField, Typography 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import backgroundImage from '../assets/images/beauty-background.jpeg';
import hairPic1 from '../assets/images/IMG-2004.JPG';
import hairPic2 from '../assets/images/IMG-2008.JPG';
import hairPic3 from '../assets/images/IMG-2011.JPG';
import hairPic4 from '../assets/images/IMG-2009.JPG';
import hairPic5 from '../assets/images/IMG-2007.JPG';

const hairPics = [
  hairPic1,
  hairPic2,
  hairPic3,
  hairPic4,
  hairPic5,
];

let i = 0;
// setInterval(() => {
//   i++;
//   console.log("Switching image", i)
// }, 5000);

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '1px -1px 10px 8px rgba(255, 105, 135, .6)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
  heroContent: {
    // backgroundColor: theme.palette.background.paper,
    backgroundImage: "url("+hairPics[2]+")",
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover",
    backgroundPosition:"center",
    padding: theme.spacing(8, 0, 6),
    height: "100vh"
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  centerContent: {
    alignItems: "center",
    textAlign: "center",
    color:"#fff",
    fontSize: "200%",
  },
  glass: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    backdropFilter: "blur 16px",
    borderRadius: "10px",
    border: "1.5px solid rgba(255, 255, 255, 0.8)",
    boxShadow: "5px 5px 8px rgba(0, 0, 0, 0.7)",
    padding: '5%',
    margin: '5%'
  },
  newsletterSide: {
    margin: 'auto',
  },
  textField: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    border: "1px solid rgba(255, 255, 255, 0.8)",
    color: "#fff",
    borderRadius: "10px",
    boxShadow: "1px -1px 20px rgba(255, 255, 255, 0.8)",
  },
  backgroundColor: {
    backgroundImage: "url("+backgroundImage+")",
    backgroundColor: "rgba(20, 22, 35, 1)",
  },
  glow: {
    boxShadow: "1px -1px 20px rgba(255, 255, 255, 0.8)",
  }
}));

const LandingPage = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    email: ""
  });

  const title = "The Artist Room";
  const subtitle = "Coming soon!";
  const buttonText = "Sign Up";

  const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const handleSubmit = async e => {
    e.preventDefault();

    console.log(state.email);

    // await Api.saveEmail(state.email);
    setState({ email: "" });
  };

  const handleChange = e => {
    setState({ [e.target.name]: e.target.value });
  };

  return (
    <>
      <Grid container spacing={2} justify="center" className={classes.backgroundColor}>
        <Grid item xs={12} sm={6} md={6} className={classes.newsletterSide}>
          <Container maxWidth="sm" className={classes.centerContent}>
            <Typography component="h1" variant="h5" align="left" color="textPrimary" gutterBottom  className={classes.centerContent}>
              <strong>{title}</strong>
            </Typography>
            <Typography variant="h1" align="left" color="textSecondary" paragraph  className={classes.centerContent}>
              {subtitle}
            </Typography>
            <div className={classes.heroButtons}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} md={8}>
                    <TextField
                      autoFocus={true}
                      name="email"
                      type="email"
                      placeholder="Your email"
                      onChange={handleChange}
                      fullWidth
                      variant="outlined"
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item sm={4} md={4}>
                    <Button type="submit" variant="contained" color="primary" className={classes.root}>
                      {buttonText}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.heroContent}>
            {/**
             * Empty column containing rotating background image of 
             * Salon photos.
            */}
          </Grid>
        </Grid>

      {/* End hero unit */}
      {/* <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Heading
                    </Typography>
                    <Typography>
                      This is a media card. You can use this section to describe the content.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary">
                      View
                    </Button>
                    <Button size="small" color="primary">
                      Edit
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container> */}
      </>
  )
}

export default LandingPage
